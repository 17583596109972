@import "../../style-p8";

/**
 Poppins
 - dans src/styles.css
 - définition de la typo dans src/style-typography-new.scss : doit être déclaré dans angular.json pour le projet
   section "styles": [
              "src/styles.css", // poppins classes si besoin
              "src/style-p8.scss",
              "src/app/helpdesk/styles.scss",
              "src/style-typography-new.scss", // $font-family: '"Poppins", "Roboto", "Helvetica Neue", sans-serif'
              "src/accessibility.scss" // accessibilité
            ]
 */

.mandatory-field {
  font-weight: bold;
  font-size: 1.1rem;
  color: red;
}
