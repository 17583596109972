@charset "UTF-8";

@font-face {
  font-family: opendys;
  src: url(fonts/opendyslexic-regular-webfont.woff2) format('woff2'), url(fonts/opendyslexic-regular-webfont.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

$excluded-elements: 'mat-icon', 'i';

@mixin apply-font($excluded-elements) {
  $selectors: ();

  @each $element in $excluded-elements {
    $selectors: append($selectors, '#{$element}', $separator: comma);
  }

  *:not(#{$selectors}) {
    font-family: 'opendys', sans-serif !important;
  }
}

.renforcer-theme {
  --bg-color: #343643;
  --txt-color: #FFFFFF;
  --accent-color: #FFCC00;
  --error-color: #FF4C4C;
  --focus-outline-color: #00FFFF;
}

.inverser-theme {
  --bg-color: #003366;
  --txt-color: #FFFF00;
  --accent-color: #00CCFF;
  --error-color: #FF0000;
  --focus-outline-color: #FFCC00;
}

.dys-font {
  @include apply-font($excluded-elements);
}

.dys-spacing * {
  line-height: 1.5 !important;
}

.modal-contrast .modal-config {
  border-width: 0 0 0 .625em;
  border-style: solid;
  border-color: #cedae6;
  background-color: #e3ebf2;
}

.modal-contrast {

  .mat-grid-tile .mat-grid-tile-header {
    display: flex;
    align-items: center;
    height: 48px;
    color:var(--txt-color) !important;
    background: transparent !important;
  }

  .mat-grid-tile .mat-grid-tile-footer {
    display: flex;
    align-items: center;
    height: 48px;
    color:var(--txt-color) !important;
    background: transparent !important;
  }
}

.custom-contrast {
  background-color: var(--bg-color) !important;
  color: var(--txt-color) !important;

  .p8-header__container div span.last {
    color: var(--txt-color) !important;
  }

  .p8-header__container mat-card.header mat-card-title  span.last {
    color: var(--txt-color) !important;
  }

  .p8-header__container mat-card.header mat-card-title  i {
    color: var(--txt-color) !important;
  }

  .item-visu {
    background-color: var(--txt-color) !important;
    color: var(--bg-color) !important;
  }

  a {
    color: var(--txt-color) !important;
    background-color: var(--bg-color) !important;

    &:hover,
    &:focus {
      color: var(--accent-color) !important;
    }
  }
}

/* High contrast for Angular Material components */
.custom-contrast {
  .mat-card,
  .mat-button,
  .mat-toolbar,
  .mat-sidenav,
  .mat-drawer-container,
  .mat-list,
  .mat-list-item,
  .mat-list-base,
  .mat-input-element,
  .mat-form-field-underline,
  .mat-select,
  .mat-checkbox .mat-checkbox-frame,
  .mat-checkbox .mat-checkbox-background,
  .mat-radio-button .mat-radio-outer-circle,
  .mat-radio-button .mat-radio-inner-circle,
  .mat-slider-thumb-container,
  .mat-slider-track-fill,
  .mat-menu-content,
  .mat-tooltip,
  .mat-table,
  .mat-expansion-panel,
  .mat-chip,
  .mat-progress-bar .mat-progress-bar-fill,
  .mat-tab-group,
  .mat-tab-label,
  .mat-tab-label.mat-tab-label-active,
  .mat-tab-body-content,
  .mat-tab-link,
  .mat-tab-link.active,
  .mat-tab-body,
  .mat-dialog-container,
  .mat-badge-content,
  .mat-paginator,
  .mat-step-header,
  .mat-grid-tile,
  .mat-slide-toggle-bar,
  .mat-slide-toggle-thumb,
  .mat-tree-node,
  .mat-progress-spinner .mat-progress-circle {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }

  /* Specific styles for elements that need different treatment */
  .mat-form-field-underline,
  .mat-progress-bar .mat-progress-bar-fill,
  .mat-progress-spinner .mat-progress-circle {
    background-color: var(--txt-color) !important;
  }

  .mat-form-field-underline,
  .mat-tab-label.mat-tab-label-active,
  .mat-badge-content {
    background-color: var(--accent-color) !important;
  }

  .mat-form-field-label,
  .mat-form-field-subscript-wrapper,
  .mat-form-field-hint,
  .mat-form-field-error,
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    color: var(--txt-color) !important;
  }

  /* Focus styles for better accessibility */
  .mat-input-element:focus,
  .mat-form-field:focus,
  .mat-select:focus,
  .mat-checkbox .mat-checkbox-frame:focus,
  .mat-radio-button .mat-radio-outer-circle:focus,
  .mat-radio-button .mat-radio-inner-circle:focus,
  .mat-slider-thumb-container:focus,
  .mat-slider-track-fill:focus,
  .mat-menu-content:focus,
  .mat-tab-label:focus,
  .mat-tab-link:focus,
  .mat-dialog-container:focus,
  .mat-paginator:focus,
  .mat-grid-tile:focus,
  .mat-slide-toggle-bar:focus,
  .mat-slide-toggle-thumb:focus,
  .mat-tree-node:focus,
  .mat-progress-spinner .mat-progress-circle:focus {
    outline: 1px solid var(--focus-outline-color) !important;
  }

  /* Button styles */
  .mat-button,
  .mat-icon-button,
  .mat-fab,
  .mat-mini-fab,
  .mat-raised-button {
    background-color: var(--txt-color) !important;
    color: var(--bg-color) !important;
    box-shadow: none !important;
  }

  .mat-button:hover,
  .mat-raised-button:hover,
  .mat-icon-button:hover,
  .mat-fab:hover,
  .mat-mini-fab:hover {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }

  .mat-button:focus,
  .mat-raised-button:focus,
  .mat-icon-button:focus,
  .mat-fab:focus,
  .mat-mini-fab:focus {
    outline: 1px solid var(--focus-outline-color) !important;
  }

  /* Expansion panel styles */
  .mat-expansion-panel,
  .mat-expansion-panel-header,
  .mat-expansion-panel-content,
  .mat-expansion-panel-content .mat-action-row {
    background-color: var(--txt-color) !important;
    color: var(--bg-color) !important;
    border: 2px solid var(--bg-color) !important;
  }

  .mat-expansion-panel-header:hover,
  .mat-expansion-panel-header:focus {
    background-color: var(--accent-color) !important;
    color: var(--bg-color) !important;
    outline: 2px solid var(--focus-outline-color) !important;
  }

  /* Table styles */
  mat-table,
  mat-header-cell,
  mat-cell,
  mat-row,
  mat-header-row,
  mat-table-sticky,
  mat-table-sticky-header,
  mat-table-sticky-footer {
    background-color: var(--txt-color) !important;
    color: var(--bg-color) !important;
    border-color: var(--bg-color) !important;
  }

  mat-table tr:hover,
  mat-row:hover {
    background-color: var(--accent-color) !important;
    color: var(--bg-color) !important;
  }

  mat-table tr:focus,
  mat-row:focus {
    outline: 2px solid var(--focus-outline-color) !important;
  }

  /* mat-select specific styles */
  .mat-select {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }

  .mat-select .mat-select-arrow {
    color: var(--txt-color) !important;
  }

  .mat-select:hover,
  .mat-select:focus {
    background-color: var(--accent-color) !important;
    color: var(--bg-color) !important;
    outline: 2px solid var(--focus-outline-color) !important;
  }

  .mat-select-panel {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }

  .mat-option {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }

  .mat-option:hover,
  .mat-option:focus,
  .mat-option.mat-active {
    background-color: var(--accent-color) !important;
    color: var(--bg-color) !important;
  }

  .mat-select-trigger {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }
  /* mat-paginator specific styles */
  .mat-paginator {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }

  .mat-paginator .mat-paginator-range-label,
  .mat-paginator .mat-paginator-page-size,
  .mat-paginator .mat-select-value,
  .mat-paginator .mat-option-text {
    color: var(--txt-color) !important;
  }

  .mat-paginator .mat-icon-button {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }

  .mat-paginator .mat-icon-button:hover {
    background-color: var(--txt-color) !important;
    color: var(--bg-color) !important;
  }

  .mat-paginator .mat-icon-button:focus {
    outline: 2px solid var(--focus-outline-color) !important;
  }

  .mat-paginator .mat-select {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }

  .mat-paginator .mat-select:hover,
  .mat-paginator .mat-select:focus {
    background-color: var(--accent-color) !important;
    color: var(--bg-color) !important;
    outline: 2px solid var(--focus-outline-color) !important;
  }

  .mat-paginator .mat-select-trigger {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }

  .mat-paginator .mat-select-arrow {
    color: var(--txt-color) !important;
  }

  .mat-paginator .mat-select-panel {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }

  .mat-paginator .mat-option {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }

  .mat-paginator .mat-option:hover,
  .mat-paginator .mat-option:focus,
  .mat-paginator .mat-option.mat-active {
    background-color: var(--accent-color) !important;
    color: var(--bg-color) !important;
  }

  mat-card-title,
  mat-card-subtitle {
    color: var(--txt-color) !important;
  }

  mat-card-actions {
    background-color: var(--txt-color) !important;
    color: var(--bg-color) !important;
  }

  mat-card-footer {
    background-color: var(--txt-color) !important;
    color: var(--bg-color) !important;
  }

  mat-checkbox {
    background-color: var(--bg-color) !important;
    color: var(--txt-color) !important;
  }

  mat-checkbox .mat-checkbox-frame {
    border: 2px solid var(--bg-color) !important;
  }

  mat-checkbox .mat-checkbox-background {
    background-color: var(--bg-color) !important;
    border: 2px solid var(--txt-color) !important;
  }

  mat-checkbox .mat-checkbox-checkmark {
    color: var(--txt-color) !important;
  }

  mat-checkbox:hover .mat-checkbox-frame {
    border-color: var(--accent-color) !important;
  }

  mat-checkbox:hover .mat-checkbox-background {
    background-color: var(--accent-color) !important;
  }

  mat-checkbox:focus .mat-checkbox-frame {
    outline: 2px solid var(--accent-color) !important;
    border-color: var(--accent-color) !important;
  }

  mat-checkbox:focus .mat-checkbox-background {
    background-color: var(--bg-color) !important;
  }

  /* Divider styles */
  .mat-divider {
    background-color: var(--txt-color) !important;
    color: var(--bg-color) !important;
  }

}
